import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    TableRow,
    TableCell,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableBody,
    Tooltip,
    Menu,
    MenuItem,
    Checkbox,
    IconButton,
    List
} from '@mui/material';
import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { useState } from 'react';
import ListIcon from '@mui/icons-material/List';
import ArticleIcon from '@mui/icons-material/Article';
import { ConjuntoLaudoContext } from 'context/LaudoContext';
import { useContext } from 'react';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { IconTextCaption } from '@tabler/icons';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ConjuntoLaudosPDF from 'views/Relatorios/PDF/ConjuntoLaudos';
import { useApi } from 'Service/axios';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';

const ModalTabelaExames = ({
    openModalExames,
    handleCloseModalExames,
    selectedExameProntuario,
    dataSelectedExameProntuario,
    prontuarioId,
    idFicha,
    paciente,
    setOpenModalExames
}) => {
    // --- INSTÂNCIAS ---
    const navigate = useNavigate();
    const api = useApi();
    // --- STATES ---

    const [selectedExames, setSelectedExames] = useState([]);
    const [updateData, setUpdateData] = useState(false);
    const [examesAtualizados, setExamesAtualizados] = useState([]);
    const [botoesAtivados, setBotoesAtivados] = useState(false);
    const { laudoPayload, setLaudoPayload, setFichaBancadaPayload } = useContext(ConjuntoLaudoContext);
    const [data, setData] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openModal, setOpenModal] = useState(null);
    const [exameProntuarioID, setexameProntuarioID] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const location = useLocation();

    // --- FUNÇÕES ---

    const formatarData = (data) => {
        const dataFormatada = new Date(data);
        const dataFormatadaString = dataFormatada.toLocaleDateString();
        const horaFormatadaString = dataFormatada.toLocaleTimeString();
        return dataFormatadaString + ' ' + horaFormatadaString;
    };

    const extrairDataEHora = (dataString) => {
        // Criar um objeto Date a partir da string
        let data = new Date(dataString);

        // Extrair os componentes da data
        let ano = data.getFullYear();
        let mes = data.getMonth() + 1; // Os meses começam do zero, então é necessário adicionar 1
        let dia = data.getDate();

        // Extrair os componentes da hora
        let horas = data.getHours();
        let minutos = data.getMinutes();
        let segundos = data.getSeconds();

        // Formatar os componentes para terem sempre dois dígitos
        mes = mes < 10 ? '0' + mes : mes;
        dia = dia < 10 ? '0' + dia : dia;
        horas = horas < 10 ? '0' + horas : horas;
        minutos = minutos < 10 ? '0' + minutos : minutos;
        segundos = segundos < 10 ? '0' + segundos : segundos;

        // Retornar a data e hora formatadas
        return `${dia}/${mes}/${ano} - ${horas}:${minutos}:${segundos}`;
    };

    const handleOpenFichaBancada = async (prontuarioId, pacienteId, exameId, idExameProntuario) => {
        window.open(`/relatorios/fichaBancada/${exameId}/numero/${pacienteId}/prontuario/${prontuarioId}/${idExameProntuario}`);
    };

    const handleOpenLaudoUnico = async (prontuarioId, exameId, exameProntuarioId, tipo) => {
        if (tipo === 'especial') {
            return window.open(`/laboratorio/resultado-especial/${prontuarioId}/${exameId}/${exameProntuarioId}`);
        }
        window.open(`/laboratorio/resultado/${prontuarioId}/${exameId}/${exameProntuarioId}`);
    };

    // checkbox control
    const handleChange = (event, item) => {
        if (event.target.checked) {
            // Checkbox foi marcado, adicione o ID aos exames selecionados
            setSelectedExames((prevState) => [
                ...prevState,
                {
                    idExameProntuario: item.id,
                    exameId: item?.exame?.id ? item?.exame?.id : null,
                    exameEspecialId: item?.exame?.id ? null : item?.exames_especiai?.id,
                    prontuarioId: parseInt(prontuarioId),
                    especial: item?.exame?.id ? false : true
                }
            ]);
            setFichaBancadaPayload((prevState) => [
                ...prevState,
                { exameId: item?.exame?.id ? item?.exame?.id : null, exameEspecialId: item?.exame?.id ? null : item?.exames_especiai?.id }
            ]);
        } else {
            // Checkbox foi desmarcado, remova o ID dos exames selecionados
            setSelectedExames((prevState) => prevState.filter((exame) => exame.idExameProntuario !== item.id));
            setFichaBancadaPayload((prevState) => prevState.filter((exame) => exame.idExameProntuario !== item.id));
        }
    };

    const handleOpenAllLaudos = async () => {
        try {
            const payload = await selectedExameProntuario.map((item) => ({
                prontuarioId: item.id_prontuario,
                exameId: item.id_exame_prontuario ? item.id_exame_prontuario : item.id_exame_especial,
                idExameProntuario: item.id
            }));

            if (selectedExames.length == 0) {
                setLaudoPayload(payload.filter((item) => item.exameId !== undefined));
            } else {
                setLaudoPayload(selectedExames);
            }

            navigate('/laboratorio/conjunto-laudos');
            window.open(`/main/laboratorio/ficha/${idFicha}/prontuario/${prontuarioId}`);
        } catch (error) {
            toast.error('Erro ao abrir todos os laudos');
        }
    };

    const handleOpenAllFichaBancadaNavigate = async () => {
        try {
            const payload = await selectedExameProntuario.map((item) => ({
                exameId: item.id_exame_prontuario ? item.id_exame_prontuario : null,
                exameEspecialId: item.id_exame_prontuario ? null : item.id_exame_especial,
                idExameProntuario: item.id
            }));

            if (selectedExames.length == 0) {
                setFichaBancadaPayload(payload);
            } else {
                setFichaBancadaPayload(selectedExames);
            }

            navigate(`/relatorios/AllFichaBancada/numero/${idFicha}/prontuario/${prontuarioId}`);
            window.open(`/main/laboratorio/ficha/${idFicha}/prontuario/${prontuarioId}`);
        } catch (error) {
            toast.error('Erro ao abrir todos as fichas');
        }
    };

    const getLaudoData = async (laudoPayload) => {
        try {
            const response = await api.getResultsForLaudos(laudoPayload);

            const agruparDados = response.data.map((el) => {
                const resultadoCampoEspecifico = el.resultado_campo_especifico;
                const data = formatarData(el?.medico?.data_execucao);

                // Use o método reduce para agrupar os campos por categoria
                const camposAgrupados = resultadoCampoEspecifico.reduce((agrupados, item) => {
                    const categoria = item.campos_especifico.categoria;

                    // Se a categoria já existir no objeto agrupados, adicione o item a ela
                    if (agrupados[categoria]) {
                        agrupados[categoria].push(item);
                    } else {
                        // Se a categoria ainda não existir, crie um novo array com o item
                        agrupados[categoria] = [item];
                    }

                    return agrupados;
                }, {});
                for (const categoria in camposAgrupados) {
                    camposAgrupados[categoria].sort((a, b) => a.id - b.id);
                }
                return {
                    ...el,
                    data,
                    camposAgrupados
                };
            });

            const ordenarExames = (a, b) => {
                const aNome = a.exame.nome.toLowerCase();
                const bNome = b.exame.nome.toLowerCase();

                if (aNome.includes('hemograma') || aNome.includes('urina')) {
                    return -1;
                } else if (bNome.includes('hemograma') || bNome.includes('urina')) {
                    return 1;
                }

                return 0;
            };

            response.data.sort(ordenarExames);

            const parsedData = laudoPayload.map(async (item) => {
                let observacao = await api.getObservation(
                    item.prontuarioId,
                    item.especial ? null : item.exameId,
                    item.especial ? item.exameId : null,
                    item.idExameProntuario
                );
                return observacao;
            });

            agruparDados.map((item) => ({ ...item, parsedData }));

            setData(response.data);

            // AQUI
        } catch (error) {
            toast.error(`Erro ao buscar dados: ${error.message}`);
        }
    };

    const teste = async () => {
        const payload = await selectedExameProntuario.map((item) => ({
            idExameProntuario: item.id,
            exameId: item?.exame?.id ? item?.exame?.id : null,
            exameEspecialId: item?.exame?.id ? null : item?.exames_especiai?.id,
            prontuarioId: parseInt(prontuarioId),
            especial: item?.exame?.id ? false : true
        }));

        if (selectedExames.length == 0) {
            setLaudoPayload(payload.filter((item) => item.exameId !== undefined));
            await getLaudoData(payload.filter((item) => item.exameId !== undefined));
        } else {
            await getLaudoData(selectedExames);
            setLaudoPayload(selectedExames);
        }
    };

    const handleDesativarExame = async (idExameProntuario) => {
        try {
            const respostaInativar = await api.desativarExames(idExameProntuario);

            if (respostaInativar.status === 200) {
                setOpenModalExames(false);
            }
        } catch (error) {}
    };

    const handleLaudoHoraUnica = async (laudoUnico) => {
        try {
            const respostaHoraunicaLaudo = await api.getHoraUnicaLaudo(laudoUnico ? laudoUnico : selectedExames);

            if (respostaHoraunicaLaudo.status === 200) {
                teste();
            }
        } catch (error) {}
    };
    useEffect(() => {}, [selectedExameProntuario]);

    function handleSaveBackPath() {
        localStorage.setItem('URL_BACK', location.pathname);
    }

    return (
        <Dialog
            open={openModalExames}
            onClose={() => {
                handleCloseModalExames();
                setSelectedExames([]);
            }}
            fullWidth
            maxWidth={'md'}
        >
            <DialogContent>
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h4">Exames: {dataSelectedExameProntuario}</Typography>
                    <Box sx={{ display: 'flex', gap: '1em' }}>
                        <Tooltip title="Geara todas as fichas de bancada">
                            <Button variant="contained" onClick={handleOpenAllFichaBancadaNavigate}>
                                <ListIcon />
                            </Button>
                        </Tooltip>
                        <Tooltip title="Gerar laudos">
                            <Button
                                id="basic-button"
                                variant="contained"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={(e) => {
                                    handleClick(e), handleLaudoHoraUnica();
                                }}
                            >
                                <SimCardDownloadIcon />
                            </Button>
                        </Tooltip>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button'
                            }}
                        >
                            <Button>
                                <PDFDownloadLink document={<ConjuntoLaudosPDF data={data} />} fileName="Laudo.pdf">
                                    {({ blob, url, loading, error }) => (loading ? 'Carregando' : 'Gerar PDF')}
                                </PDFDownloadLink>
                            </Button>
                        </Menu>
                        {/* <Tooltip title="Gerar todos os laudos">
                            <Button variant="contained" onClick={handleOpenAllLaudos}>
                                <ArticleIcon />
                            </Button>
                        </Tooltip> */}
                    </Box>
                </DialogTitle>
                <TableContainer component={Paper}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ pl: 3 }} />
                                <TableCell>
                                    <b>EXAME</b>
                                </TableCell>
                                <TableCell>
                                    <b>DATA</b>
                                </TableCell>
                                <TableCell>
                                    <b>AÇÕES</b>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {selectedExameProntuario !== null &&
                                selectedExameProntuario.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <Checkbox
                                                checked={selectedExames.some((exame) => exame.idExameProntuario === item.id)}
                                                onChange={(event) => handleChange(event, item)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                                disabled={!item.ativo} // Desativa o Checkbox se ativo for falso
                                            />
                                        </TableCell>
                                        <TableCell>{item.exame ? item.exame.nome : item.exames_especiai.nome}</TableCell>
                                        <TableCell sx={{ width: '200px' }}>{extrairDataEHora(item.data_execucao)}</TableCell>
                                        <TableCell sx={{ width: '300px' }}>
                                            {item.ativo ? (
                                                <>
                                                    <Link
                                                        to={`/main/laboratorio/ficha/${paciente?.paciente?.id}/resultado/${
                                                            item?.exame ? item?.exame?.id : item?.exames_especiai?.id
                                                        }/prontuario/${paciente?.id}/${item.id}?${
                                                            item?.exames_especiai?.id ? 'tipo=especial' : 'tipo=normal'
                                                        }`}
                                                    >
                                                        <Tooltip title="Cadastar Resultado">
                                                            <IconButton onClick={handleSaveBackPath}>
                                                                <IconTextCaption
                                                                    style={{
                                                                        color: item?.finalizado === true ? '#2196f3' : 'none'
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Link>

                                                    <Tooltip title="Ficha de bancada">
                                                        <Button
                                                            onClick={() => {
                                                                handleOpenFichaBancada(
                                                                    paciente.id,
                                                                    paciente?.paciente?.id,
                                                                    item?.exame?.id ? item?.exame?.id : item?.exames_especiai?.id,
                                                                    item.id
                                                                );
                                                            }}
                                                        >
                                                            <ListIcon />
                                                        </Button>
                                                    </Tooltip>
                                                    <Tooltip title="Laudo único">
                                                        <Button
                                                            onClick={() => {
                                                                handleLaudoHoraUnica([
                                                                    {
                                                                        idExameProntuario: item.id,
                                                                        exameId: item?.exame?.id ? item?.exame?.id : null,
                                                                        exameEspecialId: item?.exame?.id ? null : item?.exames_especiai?.id,
                                                                        prontuarioId: parseInt(prontuarioId),
                                                                        especial: item?.exame?.id ? false : true
                                                                    }
                                                                ]),
                                                                    handleOpenLaudoUnico(
                                                                        paciente.id,
                                                                        item?.exame?.id ? item?.exame?.id : item?.exames_especiai?.id,
                                                                        item.id,
                                                                        item?.exame?.id ? 'normal' : 'especial'
                                                                    );
                                                            }}
                                                        >
                                                            <ArticleIcon />
                                                        </Button>
                                                    </Tooltip>
                                                    <Tooltip title="Desativar Exames">
                                                        <Button
                                                            onClick={() => {
                                                                setOpenModal(true);
                                                                setexameProntuarioID(item.id);
                                                            }}
                                                        >
                                                            <FilterListOffIcon />
                                                        </Button>
                                                    </Tooltip>
                                                </>
                                            ) : (
                                                // Se ativo for falso, renderizar botões desativados ou omitir completamente
                                                <div>
                                                    {/* Botões desativados */}
                                                    <Tooltip title="Cadastar Resultado">
                                                        <IconButton disabled>
                                                            <IconTextCaption />
                                                        </IconButton>
                                                    </Tooltip>

                                                    {/* ... (outros botões desativados) ... */}
                                                </div>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                    <Dialog open={openModal}>
                        <DialogTitle sx={{ width: '100%' }}>
                            Deseja desativar o Exame ?
                            <Button
                                sx={{ marginLeft: '15px', maxWidth: '10px', height: '10px', padding: '10px' }}
                                variant="outlined"
                                color="error"
                                onClick={() => setOpenModal(false)}
                            >
                                X
                            </Button>
                        </DialogTitle>
                        <List sx={{ pt: 0 }}>
                            <ListItem disableGutters sx={{ alignItems: 'center', justifyContent: 'center' }}>
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        handleDesativarExame(exameProntuarioID);
                                        setOpenModal(false);
                                        setBotoesAtivados(false);
                                    }}
                                >
                                    Sim
                                </Button>
                                {/* <ListItemButton sx={{ backgroundColor: '#2196f3' }}>
                                    <ListItemText sx={{ textAlign: 'center' }} primary="Sim" />
                                </ListItemButton> */}
                            </ListItem>
                        </List>
                    </Dialog>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button
                    color="error"
                    onClick={() => {
                        handleCloseModalExames();
                        setSelectedExames([]);
                    }}
                >
                    Voltar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ModalTabelaExames;
